import React from 'react';
import * as S from './styles';
import LogoLight from './assets/images/logo.svg';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

export default class HomeComponent extends React.Component {

  render () {

    return(
      <>
      <S.Row>
        <S.PageWidth><S.Col12>
      <S.Card>
          <S.CardBody>
            
            <S.FlexCenterDirColumnDiv style={{marginLeft:'10px', marginRight:'10px'}}>
              <S.MainComponentHeaderLogo src={LogoLight} style={{width:400, marginTop:'-20px', marginBottom: '-20px'}}></S.MainComponentHeaderLogo>
              <h2 style={{fontSize:'32px', fontWeight:'bold',marginTop:'0px', marginBottom:'40px', textAlign:'center', textTransform:'uppercase'}}>Risk Assessment Tool for Sharing FCT Data</h2>
              <p>The risk assessment tool provides an easy and comprehensive way to assess the risks when sharing FCT data. The assessment is a mutli-step process that guides you to carefully examine the various risks for your own particular sharing scenario. </p>
              <p>You may visit the use case examples to see a set of already completed assessments as examples of how to complete each section.</p>
              <p>You may also look at the documentation or contact our team directly for guidance.</p>
              <S.MainComponentLinksWrapper>
              
              <Link to='/riskv6'>
                <S.MainComponentSpan >Start Assessment</S.MainComponentSpan>
              </Link>
            </S.MainComponentLinksWrapper>
              
            </S.FlexCenterDirColumnDiv>
            
          </S.CardBody>
        </S.Card>
        </S.Col12></S.PageWidth>
      </S.Row>

      <S.Row><S.PageWidth><S.Col12>
      <S.Card>
        <S.CardBody>
        <h2 style={{textAlign:'center', textTransform:'uppercase'}}>Use case Example 1</h2>
              <p style={{textAlign:'center'}}>In this demo assessment, we examine a hypothetical scenario for a FCT data exchange between ICCS (Research Institute) and Hellenic Police (LEA). ICCS requested images from traffic cameras to train an AI tool that will follow the movement of vehicles through the city.</p>
              <S.MainComponentLinksWrapper>
              
              <Link to='/newdemo'>
                <S.MainComponentSpan >Use Case Example 1</S.MainComponentSpan>
              </Link>
            </S.MainComponentLinksWrapper>
              
            
        </S.CardBody>
      </S.Card>
        
      
      </S.Col12>
      </S.PageWidth></S.Row>
      
      
      
       </>
    )
  }

}